import React, {useState, useEffect, useRef} from "react"
import Layout from "../components/layout";

import {
    TextField,
    Grid,
    IconButton,
    Select,
    RadioGroup,
    Radio,
    Button,
    FormControlLabel,
    FormLabel,
    makeStyles,
    InputLabel,
    Hidden,
    Checkbox,
    FormControl, MenuItem
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Typography from "@material-ui/core/Typography";
import SendIcon from "@material-ui/icons/Send";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useIntl, navigate} from "gatsby-plugin-intl"


import Seo from "../components/seo"
import {produce} from "immer";
import {generate} from 'shortid'
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';

import {
    sectoresMarino,
    costosSantaRosa,
    pasajerosSR,
    inputsMarino,
    horasMarino,
    settingsSR,
    feriados,
    defaultMetodoDePago
} from "../components/defaults"
import {countries, provincias} from "../components/paises";
import DisponibilidadMarino from "../components/disponibilidadMarino"
import "../components/date.css"

const recaptchaRef = React.createRef();

const useStyles = makeStyles((theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    formControl: {
        display: "flex"
    },
    displayLinebreak: {
        whiteSpace: "pre-line"
    },
    section: {
        marginTop: "1rem"
    },
    calendar: {
        border: "none"
    },
    extraTop :{
        paddingTop: "0.5 rem"
    }
}));

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

const Horizontes = () => {
    const intl = useIntl();
    const sectorACG = "Marino";
    const classes = useStyles();
    const [reservationId, setReservationId] = useState(generate());
    const [sectores, setSectores] = useState(sectoresMarino);
    const [costos] = useState(costosSantaRosa);
    const [horas, setHoras] = useState(horasMarino)
    const [inputFields, setInputFields] = useState(inputsMarino);
    const [submited, setSubmited] = useState(false)
    let [errorMsg, setErrorMsg] = useState(null)
    let [data, setData] = useState(null);
    const [total, setTotal] = useState(1100);
    const [desglosePagos, setDesglosePagos] = useState([]);
    const [cuposDisponibles, setCuposDisponibles] = useState(0);

    const [pasajeros, setPasajeros] = useState(pasajerosSR);
    const [paxCount, setpaxCount] = useState(1);
    const [disabledAddPax, setDisabledAddPax] = useState(false);
    const [disabledSubmit, setDisableSumbit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [comprobante, setComprobante] = useState([]);
    const [metodoPago, setMetodoPago] = useState(defaultMetodoDePago)
    const [diaDeCobro, setDiaDeCobro] = useState(1) // para manejar dias gratis


    let minDateDate = new Date();
    minDateDate.setDate(minDateDate.getDate() + settingsSR.diasPrevios);
    let maxiDate = new Date ();
    maxiDate.setDate(minDateDate.getDate()+31)
    //Cierre Temporal de la Isla
    const today = new Date();
    const maxIslaDate = new Date(2021,8,30);
    const lastDayToBook = new Date(2021,7,30); //ultimo día para que se muestre la isla como opción
    const [maxDate, setMaxDate] = useState(maxiDate);
    
    
    
    const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});
    const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(minDateDate);
    const fechaFormated = (`${year}-${month}-${day}`)
    
    const [fechaR, setFechaR] = useState(fechaFormated);
    const [fecha, setFecha] = useState(minDateDate);
    const [condiciones, setCondiciones]=useState(false)
    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)
    
    //Post del formulario al servidor
    useEffect(() => {
        async function sendReservation() {
            if (data !== null) {
                setLoading(true)
                const allData = new FormData();
                if (comprobante !== null){
                    comprobante.forEach(file=>{
                        allData.append(generate(), file);
                        });
                }
                allData.append('data',JSON.stringify(data))
                axios.post("https://reservaciones.acguanacaste.ac.cr/doReserve.php", allData)
                    // axios.post("http://localhost:9000/doReserve.php", data)
                    .then((response) => {
                        // console.log(response);
                        if (response.data === 'Success') {
                            navigate("/gracias/", {
                                state: {
                                    data
                                }
                            })
                            setLoading(false)
                        } else {
                            setErrorMsg(null);
                            let error = []
                            error.push(intl.formatMessage({id: "error.doReserve"})+response.data);
                            setSubmited(false);
                            setReservationId(generate());
                            setLoading(false);
                            setErrorMsg(error);
                            executeScroll()                        }
                    })
            }
        }
        sendReservation();
    }, [data]);
    
    //Calculo de costos
    useEffect(() => {
        let isSubscribed = true;
        
        async function recalcularCosto() {
            if (isSubscribed) {
                
                await setTotal(0)
                const subTDolares = pasajeros.reduce(function calcSTD(accumulator, pax) {
                    return (pax.moneda === '$') ? accumulator + pax.costo * diaDeCobro: accumulator;
                }, 0)
                const subTNacionalesLocal = pasajeros.reduce(function calcSTN(accumulator, pax) {
                    return (pax.moneda === "₡") ? accumulator + pax.costo * diaDeCobro: accumulator;
                }, 0)
                
                const ivaDolares = subTDolares * 0.13;
                const totalDolares = subTDolares + ivaDolares;
                const totalDolaresColonesLocal = totalDolares > 0 ? (totalDolares * tipoDeCambio) : 0;
                const iva = subTNacionalesLocal * 0.13;
                const SubtotalNacionalDisplay = subTNacionalesLocal ;
                const totalNacionales = subTNacionalesLocal + iva ;
                const totalColones = totalNacionales + totalDolaresColonesLocal
                const tdc = (typeof tipoDeCambio === 'number')?"(TC:"+tipoDeCambio.toString()+")":"";
                const formatColones = {maximumFractionDigits: 2,minimumFractionDigits:2, style: 'currency',currency: 'CRC'};
                const formatDolares = {maximumFractionDigits: 2,minimumFractionDigits:2, };
                const desglosePagos = [
                    {label: intl.formatMessage({id: "subtExtDol"}), value: "$"+subTDolares.toLocaleString('es-CR',formatDolares)},
                    {label: intl.formatMessage({id: "iva"}), value: "$"+ ivaDolares.toLocaleString('es-CR',formatDolares)},
                    {label: intl.formatMessage({id: "totalExt"}), value: "$"+totalDolares.toLocaleString('es-CR',formatDolares)},
                    {label: intl.formatMessage({id: "subtExtCol"})+tdc, value: totalDolaresColonesLocal.toLocaleString('es-CR',formatColones)},
                    {label: "", value: "____________"},
                    {label: intl.formatMessage({id: "subtNac"}), value: SubtotalNacionalDisplay.toLocaleString('es-CR',formatColones)},
                    {label: intl.formatMessage({id: "iva"}), value: iva.toLocaleString('es-CR',formatColones)},
                    {label: intl.formatMessage({id: "totalNac"}), value: totalNacionales.toLocaleString('es-CR',formatColones)},
                    {label: "", value: "____________"},
                    {label: intl.formatMessage({id: "total"}), value: totalColones.toLocaleString('es-CR',formatColones)},
                ];
                
                setTotal(totalColones);
                await setDesglosePagos(desglosePagos);
            }
        }
        
        recalcularCosto()
        return () => (isSubscribed = false);
    }, [pasajeros,diaDeCobro])


    async function  TipoDeCambio() {
        try {
            return await axios.get('https://reservaciones.acguanacaste.ac.cr/getTC.php')
                .then(function (response) {
                    const info = response.data
                    // console.log("tcxx:",info,response)
                    setTipoDeCambio(info)
                    return info;
                })
        } catch (error) {
            throw new Error(error);
        }
    };
    const getTipoDeCambio = TipoDeCambio;
    var  [tipoDeCambio, setTipoDeCambio] = useState(getTipoDeCambio);
    
    
    /*
    Desactiva los botones de agregar pasajero y enviar en caso de no haber cupos.
     */
    const handleCuposChanges = (cupo) => {
        setCuposDisponibles(cupo)
        if (cupo === 0){
            setDisabledAddPax(true)
        }else if (cupo < 0 ){
            setDisabledAddPax(true)
            setDisableSumbit(true)
            //  alert("No hay espacios disponibles para la fecha seleccionada");
        }
        else{
            setDisabledAddPax(false);
            setDisableSumbit(false)
        }
    }
    
    //Validación
    const validateForm = () => {
        let valid = true;
        let errores = [];
        let tmpFecha = ""
        
        inputFields.forEach(function (field, index) {
            if (field.required && (typeof field.value == "undefined" || field.value.length < 1)) {
                errores.push(field.label)
                valid = false
            }
            if (field.name === 'fecha') {
                if (field.value > maxDate){
                    errores.push("Fecha mayor a 31 días")
                }
            }
        })
        
        pasajeros.forEach(function (pax, index) {
            if (pax.nombrePax.length < 1) {
                valid = false
                errores.push(intl.formatMessage({id: "error.nombYCed"}))
            }
            if (pax.nacional.length < 1) {
                valid = false
                errores.push(intl.formatMessage({id: "error.pais"}))
            }
            
        })
        if (desglosePagos === null) {
            valid = false;
            errores.push(intl.formatMessage({id: "error.catPago"}));
        }
        if (inputFields[0].value <= new Date()) {
            valid = false;
            errores.push(intl.formatMessage({id: "error.fechaMayor"}))
        }
        if (inputFields[0].value > maxDate) {
            valid = false;
            errores.push("Fecha mayor a 31 días")
        }
        if (metodoPago === "Transferencia"){
            if (comprobante.length < 1 ){
                valid = false;
                errores.push(intl.formatMessage({id: "error.compDeposito"}))
            }
        }
        //Cierre Temporal de la Isla
        if (inputFields[11].value == "Sendero Mirador Isla San José" &&
            (fecha > maxIslaDate)
        ){
            valid = false;
            errores.push("El Sendero Isla San José está cerrado a partir del 1 de Octubre, 2021.")
            //errores.push(intl.formatMessage({id:"error.cierreIsla"}))
        }
        if (!condiciones && inputFields[11].value == "Sendero Mirador Isla San José"){
            valid = false;
            errores.push(intl.formatMessage({id: "error.condiciones"}))
        }
        if (errores.length > 0){
            errores.unshift(intl.formatMessage({id: "error.requeridos"}))
            executeScroll()
        }
        
        setErrorMsg(errores)
        return valid;
    };
    
    const handleSubmit = () => {
        setErrorMsg(null);
        setData(null);
        const validForm = validateForm();
        if (validForm && !submited) {
            setData([{
                sector: sectorACG,
                reservacion: reservationId,
                detalles: inputFields,
                sectores: sectores,
                pasajeros: pasajeros,
                fecha: fechaR,
                pago: metodoPago,
                desglosePagos,
                action : "insert",
                status : "activa",
                tipo : "turistas",
            }]);
            setSubmited(true);
        }
    }
    
    const resetForm = () => {
        setErrorMsg(null);
        setData(null);
        setPasajeros(pasajerosSR)
    }
    
    const setTheDate = (theDate) => {
        if (theDate > maxDate){
            alert("Acaba de escribir una fecha invalida")
        }
        setFecha(theDate)
        const dateTimeFormat = new Intl.DateTimeFormat('en', {year: 'numeric', month: '2-digit', day: '2-digit'});
        const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(theDate);
        const fechaFormated = (`${year}-${month}-${day}`)
        setFechaR(fechaFormated)
        setInputFields(currentInputFields =>
            produce(currentInputFields, v => {
                v[0].value = fechaFormated;
            }))
        const diaGratis = (month == 8 && day == 24)?0:1; //24 de agosto día de parques es gratis la entrada
        setDiaDeCobro(diaGratis)
    }
    
    return (
        
        <Layout sector={sectorACG}>
            <Seo title={intl.formatMessage({id: "marino.titulo"})}
                 description={intl.formatMessage({id: "marino.info1"})}
                 image={"/images/marino-cover2.jpg"}
            />
            <Grid container>
                <Grid item md={12}>
                    <Typography variant={"h6"} component={"h2"} >
                        {intl.formatMessage({id: "SM"})}
                    </Typography>
                    <Typography variant={"body2"}>
                        {intl.formatMessage({id: "marino.info2"})} <a href={"https://www.acguanacaste.ac.cr/biodesarrollo/sector-marino#actividades"} target={"_blank"} rel="noreferrer"> {intl.formatMessage({id: "SM"})}</a>.
                    </Typography>
                </Grid>

            
            </Grid>
            <form className="form">
                <Grid container spacing={3} ref={myRef}>
                    <Grid item xs={12}>
                        <FormErrors  errors={errorMsg}/>
                        {(diaDeCobro===1)?null:(
                            <Grid item container alignItems="center"
                                  style={{backgroundColor: "rgb(235,253,234)", padding: "1rem"}} xs={12}>
                                <Grid item xs={1}>
                                    <ReportProblemIcon style={{color: "#356732"}}/>
                                </Grid>
                                <Grid item xs={11} md={11}>
                                    <Typography variant={"body2"} style={{color: "#356732"}}>
                                        {intl.formatMessage({id: "diaParques"})}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={4}>
                        <FormLabel component="legend">{intl.formatMessage({id: "actividad"})}</FormLabel>
        
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <RadioGroup row aria-label="actividad" name="sectoresVisita" defaultValue="Surf" onChange={(e) => {
                            const actividad = e.target.value;
                            setInputFields(currentInputFields =>
                                produce(currentInputFields, v => {
                                    v[11].value = actividad;
                                }))
                        }}>
                            {sectores.map((sector, index) => {
                                ////Cierre Temporal de la Isla. Si es después del día de cierre de la Isla no muestre la isla
                                if (sector.label === "Sendero Mirador Isla San José" && (today > maxIslaDate)){
                                    return null
                                }else{
                                    return (
                                        <FormControlLabel key={index} value={sector.value} control={<Radio />} label={intl.formatMessage({id:sector.label })} />
                                    )
                                }
                                
                            })
                            }
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={6}  md={3}>
                        
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="fecha" className={" MuiInputLabel-shrink MuiFormLabel-filled"}>{intl.formatMessage({id: "fechaViaje"})}</InputLabel>
                            <DatePicker
                                id={"fecha"}
                                value={fecha}
                                locale={"es-CR"}
                                className={classes.calendar+ " MuiInputBase-root MuiInput-root MuiInput-underline  MuiInputBase-formControl MuiInput-formControl"}
                                onChange={(value) => {
                                    setTheDate(value)
                                }}
                                minDate={minDateDate}
                                maxDate={(inputFields[11].value === "Sendero Mirador Isla San José" &&
                                         (today >= lastDayToBook) &&
                                         (maxIslaDate < maxDate))? maxIslaDate:maxDate}
                            />
                            { /*Cierre Temporal de la Isla*/ }
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="hora">{intl.formatMessage({id: "horaLl"})}</InputLabel>
                            <Select
                                label={intl.formatMessage({id: "horaLl"})}
                                native
                                className={classes.textField}
                                value={inputFields[1].value}
                                onChange={(e) => {
                                    const hora = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[1].value = hora;
                                        }))
                                }}
                                inputProps={{
                                    name: 'hora',
                                    id: 'hora',
                                    
                                }}
                            >
                                {horas.map((unaHora, index) => {
                                    return (<option key={index} value={unaHora.value}>{unaHora.label}</option>)
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={2}>
                        
                        </Grid>
                    </Hidden>
                    <Grid item sm={12} md={4}>
                        <DisponibilidadMarino sector={sectorACG}
                                              fecha={fechaR}
                                              paxCount={pasajeros.length}
                                              cuposDisponibles={cuposDisponibles}
                                              actividad={inputFields[11].value}
                                              onChange={handleCuposChanges}
                        />
                    </Grid>
                
                </Grid>
                <Grid container spacing={3}>
                
                </Grid>
                
                <Grid container spacing={3}>
                    <Grid item xs={6} md={3}>
                        <TextField type={"email"} name={"email"} label={intl.formatMessage({id: "email"})} fullWidth required
        
                                   onChange={(e) => {
                                       const email = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[2].value = email;
                                           }))
                                   }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField type={"text"} name={"telefono"} label={intl.formatMessage({id: "tel"})} fullWidth required
        
                                   onChange={(e) => {
                                       const telefono = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[13].value = telefono;
                                           }))
                                   }}
                        />
                    </Grid>
                </Grid>
               
                <Grid container spacing={3}>
                    <Grid item xs={6} md={3}>
                        <TextField name="embacacion" label={intl.formatMessage({id: "Embarcación"})} fullWidth required
                                   onChange={(e) => {
                                       const embarcacion = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[3].value = embarcacion;
                                           }))
                                   }}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <TextField name="matricula" label={intl.formatMessage({id: "matr"})} fullWidth required
                                   onChange={(e) => {
                                       const matricula = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[4].value = matricula;
                                           }))
                                   }}
                        />
                    </Grid>
                </Grid>
                
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} md={3}>
                        <TextField name="capitan" label={intl.formatMessage({id: "capitan"})} fullWidth required
                                   onChange={(e) => {
                                       const capitan = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[5].value = capitan;
                                           }))
                                   }}
                        />
                    </Grid>
                    
                    
                    
                    <Grid item xs={5} md={3}>
                        
                        <FormControl className={classes.formControl}>
                            <InputLabel id="pais-label">{intl.formatMessage({id: "pais"})}</InputLabel>
                            <Select
                                labelId="pais-label"
                                id="pais"
                                onChange={(e) => {
                                    const nacional = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[6].value = nacional;
                                        }))
                                }}
                            >
                                {countries.map((unPais, index) => {
                                    return (
                                        <MenuItem key={index} value={unPais.name}>{unPais.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    
                    </Grid>
                    <Grid item md={2}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="provincia-label">{intl.formatMessage({id: "provincia"})}</InputLabel>
                            <Select
                                labelId="provincia-label"
                                id="provincia"
                                onChange={(e) => {
                                    const provincia = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[7].value = provincia;
                                        }))
                                }}
                            >
                                {provincias.map((unaProvincia, index) => {
                                    return (
                                        <MenuItem key={index} value={unaProvincia.provincia}>{unaProvincia.provincia}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                
                </Grid>
                
                
                
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={6} md={3}>
                        <TextField name="marinero" label={intl.formatMessage({id: "mari"})} fullWidth
                                   onChange={(e) => {
                                       const capitan = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[8].value = capitan;
                                           }))
                                   }}
                        />
                    </Grid>
                    
                    
                   
                    <Grid item xs={5} md={3}>
                        
                        <FormControl className={classes.formControl}>
                            <InputLabel id="pais-label">{intl.formatMessage({id: "pais"})}</InputLabel>
                            <Select
                                labelId="pais-label"
                                id="paisMarinero"
                                onChange={(e) => {
                                    const nacional = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[9].value = nacional;
                                        }))
                                }}
                            >
                                {countries.map((unPais, index) => {
                                    return (
                                        <MenuItem key={index} value={unPais.name}>{unPais.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    
                    </Grid>
                    <Grid item md={2}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="provincia-label">{intl.formatMessage({id: "provincia"})}</InputLabel>
                            <Select
                                labelId="provincia-label"
                                id="provincia"
                                onChange={(e) => {
                                    const provincia = e.target.value;
                                    setInputFields(currentInputFields =>
                                        produce(currentInputFields, v => {
                                            v[10].value = provincia;
                                        }))
                                }}
                            >
                                {provincias.map((unaProvincia, index) => {
                                    return (
                                        <MenuItem key={index} value={unaProvincia.provincia}>{unaProvincia.provincia}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                
                </Grid>
                
                
                
                
                <Grid container className={classes.section}  spacing={3} alignItems="center">
                    <Grid item xs={12}>
                        <FormLabel component="legend">{intl.formatMessage({id: "pasajeros"})}</FormLabel>
                        <Typography variant={"body2"}>{intl.formatMessage({id: "paxinfoM"})}</Typography>
                    </Grid>
                    
                    <Grid item xs={12}>
                        {inputFields[11].value == "Sendero Mirador Isla San José" &&
                        <Alert severity="warning">
                            {intl.formatMessage({id:"marino.list"})}
                        </Alert>
                        }
                        
                    </Grid>
                </Grid>
                
                
                {pasajeros.map((field, index) => {
                    // console.log("renderx")
                    return (
                        <Grid key={field.id} container spacing={3}>
                            <Grid item xs={6} md={3}>
                                <TextField name="nombre" label={intl.formatMessage({id: "nombre"})} required value={field.nombrePax} fullWidth
                                           onChange={(e) => {
                                               const nombre = e.target.value;
                                               setPasajeros(currentPasajeros =>
                                                   produce(currentPasajeros, v => {
                                                       v[index].nombrePax = nombre;
                                                   }))
                                           }}
                                />
                            </Grid>
                           
                            <Grid item xs={5} md={3}>
                                
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="pais-label">{intl.formatMessage({id: "pais"})}</InputLabel>
                                    <Select
                                        labelId="pais-label"
                                        id="pais"
                                        value={field.nacional}
                                        onChange={(e) => {
                                            const nacional = e.target.value;
                                            setPasajeros(currentPasajeros =>
                                                produce(currentPasajeros, v => {
                                                    v[index].nacional = nacional;
                                                    if (nacional !== "Costa Rica"){
                                                        v[index].tarifa = "adultoExtranjero";
                                                        v[index].costo = 15* diaDeCobro;
                                                        v[index].moneda = "$";
                                                    }else{
                                                        v[index].tarifa = "adulto";
                                                        v[index].costo = 1100* diaDeCobro;
                                                        v[index].moneda = "₡";
                                                    }
                                                }))
                                        }}
                                    >
                                        {countries.map((unPais, index) => {
                                            return (
                                                <MenuItem key={index} value={unPais.name}>{unPais.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            
                            </Grid>
                            <Grid item md={2}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel id="provincia-label">{intl.formatMessage({id: "provincia"})}</InputLabel>
                                    <Select
                                        labelId="provincia-label"
                                        id="provincia"
                                        value={field.provincia}
                                        onChange={(e) => {
                                            const provincia = e.target.value;
                                            setPasajeros(currentPasajeros =>
                                                produce(currentPasajeros, v => {
                                                    v[index].provincia = provincia;
                                                }))
                                        }}
                                    >
                                        {provincias.map((unaProvincia, index) => {
                                            return (
                                                <MenuItem key={index} value={unaProvincia.provincia}>{unaProvincia.provincia}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} md={1}>
                                <label htmlFor="icon-button-file">
                                    <IconButton aria-label={intl.formatMessage({id: "borpax"})} component="span"
                                                onClick={() => {
                                                    setPasajeros(currentPasajeros => currentPasajeros.filter(x => x.id !== field.id))
                                                    setpaxCount(pasajeros.length)
                                                }}
                                    >
                                        <DeleteForeverIcon color="error"/>
                                    </IconButton>
                                </label>
                            </Grid>
                            <Grid item xs={12} md={11}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{intl.formatMessage({id: "tarifa"})}</FormLabel>
                                    <RadioGroup row aria-label="tarifa" name="costo" value={field.tarifa}
                                                onChange={(e) => {
                                                    const tarifa = e.target.value;
                                                    const infoTarifa = costos.filter((unaCategoria) => {
                                                        return (unaCategoria.name === tarifa)
                                                    })
                                                    let costo = infoTarifa[0].value;
                                        
                                                    setPasajeros(currentPasajeros =>
                                                        produce(currentPasajeros, v => {
                                                            v[index].tarifa = tarifa;
                                                            v[index].costo = costo * diaDeCobro;
                                                            v[index].moneda = infoTarifa[0].labelCurrency
                                                        }));
                                                }}>
                                        
                                        {
                                            costos.map((unaCategoria, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={unaCategoria.name}
                                                        value={unaCategoria.name}
                                                        control={<Radio/>}
                                                        label={intl.formatMessage({id: unaCategoria.label})}
                                                    
                                                    />
                                                )
                                            })}
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                                {field.moneda + field.costo}
                            </Grid>
                        </Grid>
                    
                    )
                })}
                
                
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        
                        <Button
                            variant="outlined"
                            color="primary"
                            disabled={disabledAddPax}
                            onClick={() => {
                                setPasajeros(currentPasajeros => [...currentPasajeros, {
                                    id: generate(),
                                    nombrePax: "",
                                    nacional: "",
                                    provincia: "",
                                    costo: 1100,
                                    tarifa: "adulto",
                                    moneda: "₡"
                                }])
                                setpaxCount(pasajeros.length)
                            }}
                            
                            startIcon={<AddCircleIcon/>}
                        >{intl.formatMessage({id: "persona"})}
                        </Button>
                    </Grid>
                
                </Grid>
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <FormLabel component="legend">{intl.formatMessage({id: "pres"})}</FormLabel>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <Typography className={classes.displayLinebreak} variant={"body2"}>
                            {intl.formatMessage({id:"descDep"})} "Visita al Sector {sectorACG}, ACG"
                            
                            <br /><br />
                            {intl.formatMessage({id:"ctaColSINAC1"})} <br/>
                            {intl.formatMessage({id:"ctaColSINAC2"})} <br/>
                            {intl.formatMessage({id:"ctaColSINAC3"})} <br/>
                            {intl.formatMessage({id:"ctaColSINAC4"})} <br/>
                            {intl.formatMessage({id:"ctaColSINAC5"})}SINAC FONDO DE PARQUES NACIONALES <br/>
                            {intl.formatMessage({id:"ctaColSINAC6"})} <br/>
                            <br />
                            {intl.formatMessage({id:"ctaDolSINAC1"})} <br/>
                            {intl.formatMessage({id:"ctaDolSINAC2"})} <br/>
                            {intl.formatMessage({id:"ctaDolSINAC3"})} <br/>
                            {intl.formatMessage({id:"ctaDolSINAC4"})} <br/>
                            {intl.formatMessage({id:"ctaDolSINAC5"})}SINAC FONDO DE PARQUES NACIONALES <br/>
                            {intl.formatMessage({id:"ctaDolSINAC6"})} <br/>

                        </Typography>
                    </Grid>
                    <Grid item md={1} display={{xs: 'none'}}>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {desglosePagos.map((pago, index) => {
                            return (
                                <Grid key={index} item xs={12} container justifyContent={"flex-end"}>
                                    <Grid item xs={9}>
                                        <Typography variant={"body1"}>{pago.label}</Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{textAlign: "right"}}>
                                        <Typography variant={"body1"}>
                                            <strong> {pago.value}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                
                
                </Grid>
                
                
                
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>
                            {intl.formatMessage({id: "numDep"})}
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={6} md={4}>
                        <TextField name="motivo" label={intl.formatMessage({id: "depNum"})} fullWidth required
                                   onChange={(e) => {
                                       const desposito = e.target.value;
                                       setInputFields(currentInputFields =>
                                           produce(currentInputFields, v => {
                                               v[12].value = desposito;
                                           }))
                                   }}
                        />
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor={"comprobante"} id="comprobante-label">{intl.formatMessage({id: "comprob"})}</InputLabel>
                            <input accept="image/*, application/pdf"
                                   id="comprobante"
                                   type="file"
                                   multiple={true}
                                   onChange={(e) => {
                                       let files = [];
                                       for (let i=0; i< e.target.files.length; i++){
                                           files.push(e.target.files[i]);
                                       }
                                       setComprobante(files)
                                   }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                
                {(inputFields[11].value == "Sendero Mirador Isla San José") && (
                    <Grid item xs={12 } >
                        <FormControlLabel
                            control={<Checkbox checked={condiciones} onChange={(e)=>{setCondiciones(e.target.checked)}} name="gilad" />}
                            label={<span>He leído, entiendo y acepto las <a href={"https://www.acguanacaste.ac.cr/images/documentos/Isla-San-Jose-Regulaciones-caminata-DASP-ACG.pdf"} target="_blank">Regulaciones e indicaciones básicas para el ingreso al mirador en Isla San José</a></span>}
                            className={classes.extraTop}
                        > </FormControlLabel>
    
                    </Grid>
                )}
                
                <Grid container spacing={3}>
                    <Grid item xs={6} md={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            type={"Submit"}
                            disabled={disabledSubmit || submited}
                            onClick={(e) => {
                                e.preventDefault();
                                handleSubmit()
                            }}
                            startIcon={<SendIcon/>}
                        >{intl.formatMessage({id: "enviar"})}
                        </Button>
                        {loading ? <div><CircularProgress /> {intl.formatMessage({id: "espere"})}</div> :null}
                    </Grid>
                    
                    <Grid item xs={6} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                resetForm()
                            }}
                            startIcon={<RotateLeftIcon/>}
                        >{intl.formatMessage({id: "limpiar"})}
                        </Button>
                    </Grid>
                </Grid>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LdtF6YZAAAAADQ-dNM1T1N7OY7UnQ8VioDwGVP0"
                />
            </form>
        </Layout>
    )
}

const FormErrors = (props) => {
    const errores = props.errors;
    return (
        <>
            {(errores !== null && errores.length > 0) && (
                <Grid container alignItems="center" style={{backgroundColor: "rgb(253, 236, 234)", padding: "1rem"}}>
                    <Grid item xs={1}>
                        <ReportProblemIcon style={{color: "#ad1d1d"}}/>
                    </Grid>
                    <Grid item xs={11} md={11}>
                        <Typography variant={"body2"} style={{color: "#ad1d1d"}}>
                            {errores.map((error, index) => {
                                return (
                                    (index>1 ? ', ' : '') + error
                                )
                            })
                            }
                        </Typography>
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default Horizontes
