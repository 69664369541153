import React, {useEffect, useState} from "react";
import axios from "axios";
import {
    makeStyles,
    withStyles,
    FormLabel,
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody
} from "@material-ui/core";
import { useIntl} from "gatsby-plugin-intl"

const useStyles = makeStyles((theme) => ({
    codigoRes: {
        padding: "0.5rem",
        backgroundColor: "rgb(211,47,47)",
        color: "white",
        
    },
    disponibilidades: {
        display: "flex",
        justifyContent: "space-between"
    },
    tableRightBorder : { border:0, borderLeft:1, borderColor: 'white',borderStyle: 'solid', color: 'white'} // or borderTop: '1px solid red'
    
}));


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "rgb(211,47,47)",
        color: "white"
    },
    body: {
        fontSize: 14,
        color: "white"
    },
    td: {
        borderLeft: "1px solid white"
    }
    
}))(TableCell);

const DisponibilidadMarino = (props) => {
    const intl = useIntl();
    const classes = useStyles();
    const [disponibleSurf, setDisponibleSurf] = useState(0)
    const [disponibleBuceo, setDisponibleBuceo] = useState(0)
    const [disponiblePaseo, setDisponiblePaseo] = useState(0)

    async function paxConReserva() {
        await axios.get("https://reservaciones.acguanacaste.ac.cr/getDisponibilidad.php", {
            params: {
                fecha: props.fecha,
                sector: props.sector,
                disponibilidad: 1
            }
        })
            .then((response) => {
                let tmp = {
                    "Surf": 0,
                    "Buceo": 0,
                    "Paseo recreativo": 0,
                    "Sendero Mirador Isla San José":0
                };
                response.data.forEach((field) => {
                    tmp[field._id] = field.pax;
                })
                
                let dSurf = 65 - tmp['Surf'] ;
                let dBuceo = 40 - tmp['Buceo'] ;
                let dPaseo = 35 - tmp['Paseo recreativo'];

                switch (props.actividad){
                    case "Surf":{
                        dSurf = dSurf - props.paxCount;
                        props.onChange(dSurf);
                        break;
                    }
                    case "Buceo":{
                        dBuceo = dBuceo - props.paxCount;
                        props.onChange(dBuceo);
                        break;
                    }
                    case "Paseo recreativo":{
                        dPaseo = dPaseo - props.paxCount;
                        props.onChange(dPaseo);
                        break;
                    }
                }
                setDisponibleSurf(dSurf);
                setDisponibleBuceo(dBuceo);
                setDisponiblePaseo(dPaseo);
            })
            .catch(function (error) {
                console.log(error)
            })
    }
    
    useEffect(() => {
        paxConReserva()
    }, [props.fecha, props.sector, props.paxCount, props.cuposDisponibles, props.actividad])
    
    useEffect(() => {
        paxConReserva()
    }, [])
    return (
        <>
            <FormLabel component="legend">{intl.formatMessage({id: "cd"})}</FormLabel>
    
    
            <Table aria-label="simple table" className={classes.codigoRes}>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableRightBorder}>{intl.formatMessage({id: "Surf"})}</TableCell>
                        <TableCell className={classes.tableRightBorder}>{intl.formatMessage({id: "Buceo"})}</TableCell>
                        <TableCell className={classes.tableRightBorder}>{intl.formatMessage({id: "paseosR"})}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                   
                        <TableRow key={"des"} scope="row">
                            <TableCell className={classes.tableRightBorder}>{(disponibleSurf >= 0) ? disponibleSurf : 0}</TableCell>
                            <TableCell className={classes.tableRightBorder}>{(disponibleBuceo >= 0) ? disponibleBuceo : 0}</TableCell>
                            <TableCell className={classes.tableRightBorder}>{(disponiblePaseo >= 0) ? disponiblePaseo : 0}</TableCell>
                        </TableRow>
                </TableBody>
            </Table>
        </>
    )
}

export default DisponibilidadMarino

